<template>
  <b-modal
    ref="pdf-viewer"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="PDF Viewer"
    size="alert"
    @hidden="closeModal()"
    hide-footer
    centered
    body-class="p-0"
  >
    <div v-if="isLoading" class="is-loading">
      <span class="loader" :class="{ dark: isDarkSkin }"></span>
    </div>
    <div class="d-flex flex-column align-items-center relative">
      <div class="container-pdf">
        <div
          @mousedown.stop="startDrag"
          @mousemove.stop="drag"
          @mouseup.stop="endDrag"
          :style="scaleFun"
        >
          <vue-pdf-embed
            ref="pdfRef"
            :page="page"
            :source="url"
            :height="750"
            @rendered="handleDocumentRender"
          />
        </div>
      </div>
      <div class="container-actions m-1">
        <div class="actions">
          <feather-icon
            icon="ChevronLeftIcon"
            class="clickable"
            @click="!disabledPrev && changePage(-1)"
            size="20"
          />
          <span class="mx-1">{{ page }} / {{ numPages }}</span>
          <feather-icon
            icon="ChevronRightIcon"
            @click="!disabledNext && changePage(1)"
            class="clickable"
            size="20"
          />
        </div>
        <div class="actions">
          <feather-icon
            icon="ZoomInIcon"
            class="clickable"
            size="20"
            @click="zoomIn()"
          />
          <b-dropdown
            id="dropdown-1"
            :text="`${scale * 100}%`"
            variant="outline-primary"
            class="m-1"
            size="sm"
          >
            <b-dropdown-item
              v-for="option in optionsScale"
              :key="option.value"
              @click="scale = option.value"
            >
              {{ option.text }}
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon
            icon="ZoomOutIcon"
            class="clickable"
            size="20"
            @click="zoomOut()"
          />
          <feather-icon
            icon="MaximizeIcon"
            class="clickable"
            size="20"
            @click="fitScale()"
          />
        </div>
        <div class="actions">
          <feather-icon
            @click="downloadPDF()"
            icon="DownloadIcon"
            class="clickable"
            size="20"
          />
          <feather-icon
            @click="printPDF()"
            icon="PrinterIcon"
            class="clickable"
            size="20"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    url: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      numPages: 0,
      scale: 1,
      optionsScale: [
        { value: 1, text: "100%" },
        { value: 1.5, text: "110%" },
        { value: 2, text: "125%" },
        { value: 2.5, text: "150%" },
        { value: 3, text: "175%" },
        { value: 3.5, text: "200%" },
        { value: 4, text: "250%" },
      ],
      dragState: {
        isDragging: false,
        startX: 0,
        startY: 0,
        translateX: 0,
        translateY: 0,
      },
    };
  },
  computed: {
    disabledPrev() {
      let page = Number(this.page);
      return page == 1;
    },
    disabledNext() {
      let page = Number(this.page);
      return page === this.numPages;
    },
    scaleFun() {
      return {
        transform: `scale(${this.scale}) translate(${this.dragState.translateX}px, ${this.dragState.translateY}px)`,
        cursor: this.dragState.isDragging
          ? "grabbing"
          : this.scale === 1
          ? "auto"
          : "grab",
      };
    },
  },
  mounted() {
    this.toggleModal("pdf-viewer");
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.numPages = this.$refs.pdfRef.pageCount;
    },
    zoomIn() {
      this.scale = this.scale + 0.5;
    },
    zoomOut() {
      this.scale = this.scale - 0.5;
    },
    fitScale() {
      this.scale = 1;
      this.dragState.translateX = 0;
      this.dragState.translateY = 0;
    },
    downloadPDF() {
      this.$refs.pdfRef.download();
    },
    printPDF() {
      this.$refs.pdfRef.print(100, `pdf-${new Date().getTime()}.pdf`, true);
    },
    closeModal() {
      this.$emit("close");
    },
    changePage(page) {
      this.page = Number(this.page) + page;
    },
    startDrag(event) {
      if (this.scale === 1) return;
      this.dragState.isDragging = true;
      this.dragState.startX = event.clientX - this.dragState.translateX;
      this.dragState.startY = event.clientY - this.dragState.translateY;
    },

    drag(event) {
      if (!this.dragState.isDragging) return;
      this.dragState.translateX = event.clientX - this.dragState.startX;
      this.dragState.translateY = event.clientY - this.dragState.startY;
    },
    endDrag() {
      if (!this.dragState.isDragging) return;
      this.dragState.isDragging = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-actions {
  position: absolute;
  bottom: 10px;
  width: 75%;
  height: 45px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 45px;
  background-color: #17171a;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}
.container-pdf {
  height: 100%;
  background-color: #fff;
  margin: 10px;
  overflow: auto;
}
.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}
.is-loading {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #0090e7;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  &.dark {
    border: 5px solid #17171a;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
