<template>
  <div>
    <filter-slot
      :filter="filterSlot.filters"
      :filterSearch="filterSlot.filterSearch"
      :paginate="filterSlot.paginate"
      :totalRows="filterSlot.totalRows"
      :startRow="filterSlot.startRow"
      :endRow="filterSlot.endRow"
      :toPage="filterSlot.toPage"
      @reset-all-filters="resetAllFilters"
      @reload="$refs['letterRoundTable'].refresh()"
    >
      <b-table
        ref="letterRoundTable"
        slot="table"
        class="blue-scrollbar"
        :items="myProvider"
        :fields="visibleFields"
        :busy.sync="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="filterSlot.paginate.perPage"
        :current-page="filterSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(client)="data">
          <span class="d-flex align-items-center" style="gap: 5px">
            <div>
              <router-link
                target="_blank"
                :to="{
                  name: clientDashboardRouteName,
                  params: {
                    idClient: `${data.item.client_account_id}`,
                  },
                }"
              >
                {{ data.item.client_name }}
              </router-link>
            </div>
          </span>
          <status-account :account="data.item" :text="true" />
          <span class="d-block">
            {{ data.item.client_account }}
          </span>
        </template>

        <template #cell(type)="data">
          <div>
            <b-badge :variant="badgeVariant(data.item.type_send)">
              {{ data.item.type_send }}
            </b-badge>
          </div>
        </template>

        <template #cell(round)="data">
          <div>
            <b-badge
              variant="light-primary"
              @click="openModalAddRound(data.item)"
              class="cursor-pointer"
            >
              {{ data.item.round_title.split(" ")[0] }}
              ({{ data.item.sumeq + data.item.sumex + data.item.sumtr }})
            </b-badge>
          </div>
          <div class="text-danger" v-if="data.item.expired_date">
            Expired:{{ data.item.expired_date | myGlobal }}
          </div>
        </template>

        <template #cell(created_by)="data">
          <div>
            {{ data.item.created_by }}
            <br />
            {{ data.item.created_at | myGlobalDay }}
          </div>
        </template>

        <template #cell(date_send)="data">
          {{ data.item.date_send | myGlobalDay }}
        </template>

        <template #cell(procesed_by)="data">
          <div>
            {{ data.item.procesed_by }}
            <br />
            {{ data.item.date_specialist | myGlobalDay }}
          </div>
        </template>

        <template #cell(deleted_by)="data">
          <div>
            {{ data.item.deleted_by }}
            <br />
            {{ data.item.deleted_at | myGlobalDay }}
          </div>
        </template>

        <template #cell(tracking)="data">
          <div>
            <feather-icon
              class="text-info cursor-pointer"
              v-b-tooltip.hover.right="'View Tracking'"
              size="16"
              icon="ListIcon"
              @click="openModalTracking(data.item)"
            />
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret boundary="viewport">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
                v-b-tooltip.hover.right="'View Actions'"
              />
            </template>
            <b-dropdown-item @click="openModalProcess(data.item)">
              <feather-icon
                class="text-primary cursor-pointer mr-1"
                icon="SendIcon"
                size="16"
              />
              <span class="align-middle ml-50 text-primary">{{
                data.item.state == 2 ? "Send to Correspondence" : "Process"
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="
                openModalEditRoundLetter(
                  data.item.id,
                  data.item.client_account_id,
                  data.item.client_account,
                  data.item.client_name
                )
              "
            >
              <feather-icon
                class="text-warning cursor-pointer mr-1"
                icon="EditIcon"
                size="16"
              />
              <span class="text-warning align-middle ml-50">Edit Round</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteRoundLetter(data.item.id)">
              <feather-icon
                class="text-danger cursor-pointer mr-1"
                icon="TrashIcon"
                size="16"
              />
              <span class="text-danger align-middle ml-50">Delete Round</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #cell(file)="data">
          <div class="d-flex justify-content-center">
            <feather-icon
              icon="FolderIcon"
              size="16"
              class="cursor-pointer"
              v-b-tooltip.hover.top="'Files'"
              @click="openModalFiles(data.item)"
            />
          </div>
        </template>
        <template #cell(generated_merge)="data">
          <template v-if="data.item.generated_court === 'YES'">
            <template v-if="data.item.generated_merge === 'NO'">-</template>
            <template v-else>
              <div class="d-flex flex-column">
                <b-button
                  variant="outline-danger"
                  class="mx-auto"
                  style="margin-bottom: 5px; width: 120px"
                  size="sm"
                  @click="viewPdfMerge(data.item.generated_merge, data.item)"
                >
                  <amg-icon
                    class="text-danger"
                    icon="FilePdfIcon"
                    style="height: 15px; width: 15px"
                  />
                  Merged
                </b-button>
              </div>
            </template>
          </template>
          <template v-else> - </template>
        </template>
        <template #cell(generated_court)="data">
          <b-button
            :variant="
              data.item.generated_court === 'YES'
                ? 'outline-success'
                : 'outline-warning'
            "
            size="sm"
            @click="generateCourt(data.item)"
            >{{
              data.item.generated_court === "YES" ? "Generated" : "Generate"
            }}</b-button
          >
        </template>
      </b-table>
    </filter-slot>
    <ModalFiles
      :dataFile="dataModalFile"
      v-if="dataModalFile"
      @hideModal="dataModalFile = null"
    />

    <modal-tracking-letter-round
      v-if="modalTrackingState"
      :dataClient="dataClient"
      @hideModal="modalTrackingState = false"
    />

    <modal-edit-round-letter
      v-if="modalEditRoundLetterState"
      :idround="ncrId"
      :idaccount="clientAccountId"
      :account="accountId"
      :nameClient="nameClient"
      @updateTable="$refs['letterRoundTable'].refresh()"
      @hideModal="modalEditRoundLetterState = false"
    />

    <modal-process
      v-if="modalProcessState"
      :dataClient="dataProcess"
      @updateTable="$refs['letterRoundTable'].refresh()"
      @hideModal="modalProcessState = false"
    />

    <PdfViewer
      :url="urlPdfMerge"
      v-if="urlPdfMerge"
      @close="urlPdfMerge = null"
    />

    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      :show-all-letters="false"
      @close="modalAddRoundLetterState = false"
    />
    <ModalMergeFiles
      v-if="mergeFileModal.item"
      @close="closeModalMerge"
      :item="mergeFileModal.item"
      :viewMergedPdf="mergeFileModal.viewMergedPdf"
    />
  </div>
</template>
<script>
import PdfViewer from "@/views/components/pdf-viewer/PdfViewer.vue";

// DATA
import Fields from "@/views/specialists/sub-modules/analyst-department/views/letter-round/data/fields.js";
import Filters from "@/views/specialists/sub-modules/analyst-department/views/letter-round/data/filters.js";
import FilterSlot from "@/views/specialist-digital/views/updates/slot/FilterSlot.vue";

// COMPONENTS
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";
import ModalTrackingLetterRound from "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/modals/ModalTrackingLetterRound.vue";
import ModalEditRoundLetter from "@/views/specialist-digital/views/letters-round/components/modal/ModalEditRoundLetter.vue";
import ModalProcess from "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/modals/ModalProcessRoundLetter.vue";
import ModalFiles from "@/views/administration/views/letters/components//modal/ModalFiles.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";

// SERVICE
import ClientOptions from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import LettersRoundsService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";

// STORE
import { mapGetters, mapActions } from "vuex";
import ModalMergeFiles from "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/modals/ModalMergeFiles.vue";

import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  name: "LetterRoundTable",
  components: {
    IconStatusAccount,
    FilterSlot,
    ModalTrackingLetterRound,
    ModalEditRoundLetter,
    ModalProcess,
    ModalFiles,
    PdfViewer,
    AddRoundModal,
    ModalMergeFiles,
    StatusAccount,
  },
  data() {
    return {
      urlPdfMerge: null,
      mergeFileModal: {
        item: null,
        viewMergedPdf: false,
      },
      Fields,
      filterSlot: {
        filters: Filters,
        filterSearch: {
          type: "input",
          inputType: "text",
          placeholder: "Client...",
          model: "",
        },
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        //rows
        totalRows: 0,
        startRow: 0,
        endRow: 0,
        toPage: 0,
      },
      isBusy: false,
      modalTrackingState: false,
      dataClient: [],
      modalEditRoundLetterState: false,
      ncrId: null,
      clientAccountId: null,
      accountId: null,
      nameClient: null,
      modalProcessState: false,
      dataProcess: [],
      dataModalFile: null,
      modalAddRoundLetterState: false,
      itemClient: null,
    };
  },
  props: {
    tabSelected: String,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
    }),

    isCreditAnalyst() {
      return this.currentUser.role_id == 22;
    },

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    isToSendTab() {
      const { routeToSend } = this.$route.matched[1].meta;
      return routeToSend == this.$route.name;
    },
    isPendingTab() {
      const { routePending } = this.$route.matched[1].meta;
      return routePending == this.$route.name;
    },
    isCompletedTab() {
      return this.isInCorrespondenceSubTab || this.isSentSubTab;
    },
    isDeletedTab() {
      const { routeDeleted } = this.$route.matched[1].meta;
      return routeDeleted == this.$route.name;
    },

    isExpiredTab() {
      const { routeExpired } = this.$route.matched[1].meta;
      return routeExpired == this.$route.name;
    },

    isInCorrespondenceSubTab() {
      const { routeCompletedInCorrespondence } = this.$route.matched[2].meta;
      return routeCompletedInCorrespondence == this.$route.name;
    },

    isSentSubTab() {
      const { routeCompletedSent } = this.$route.matched[2].meta;
      return routeCompletedSent == this.$route.name;
    },

    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    visibleFields() {
      return this.Fields.filter((field) => field.visible);
    },

    setState() {
      if (this.isToSendTab) return 2;
      if (this.isPendingTab) return 1;
      if (this.isInCorrespondenceSubTab) return 5;
      if (this.isSentSubTab) return 7;
      if (this.isExpiredTab) return 11;
      return null;
    },
    typeTab() {
      if (this.isToSendTab) return 1;
      if (this.isPendingTab) return 2;
      if (this.isCompletedTab) return 4;
    },

    validateModal() {
      return this.isPendingTab ? 0 : 3;
    },
  },

  async created() {
    await this.start();
  },

  methods: {
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
      A_COUNTER_PENDING_ROUND_LETTERS:
        "SpecialistsDigitalRoundLettersStore/A_COUNTER_PENDING_ROUND_LETTERS",
    }),
    async start() {
      // FIELDS/
      this.filterColumn("deleted_by", this.isDeletedTab);
      this.filterColumn("created_by", !this.isDeletedTab);
      this.filterColumn("actions", this.isPendingTab || this.isToSendTab);
      this.filterColumn("tracking", !this.isDeletedTab);
      this.filterColumn("file", !this.isDeletedTab);
      this.filterColumn("generated_merge", this.isToSendTab);
      this.filterColumn("procesed_by", this.isInCorrespondenceSubTab);
      this.filterColumn("created_by", !this.isInCorrespondenceSubTab);
      this.filterColumn("date_send", this.isSentSubTab);
      this.getFilterByKey("created_by").visible = !this.isCreditAnalyst;
      this.getFilterByKey("sent_to").visible = this.isSentSubTab;
      this.getFilterByKey("sent_from").visible = this.isSentSubTab;
    },
    getFilterByKey(key) {
      return this.filterSlot.filters.find((filter) => filter.key === `${key}`);
    },

    resetAllFilters() {
      this.filterSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterSlot.filterSearch.model = null;
      this.$refs.letterRoundTable.refresh();
    },

    async myProvider(ctx) {
      try {
        let params = {
          nameText: this.filterSlot.filterSearch.model,
          dateFrom: this.getFilterByKey("from").model,
          dateTo: this.getFilterByKey("to").model,
          sentFrom: this.getFilterByKey("sent_from").model,
          sentTo: this.getFilterByKey("sent_to").model,
          module: this.getFilterByKey("module").model,
          specialist: this.getFilterByKey("created_by").model,
          state: this.setState,
          typeSend: this.getFilterByKey("type").model,
          isDeleted: this.isDeletedTab,
          ...ctx,
        };
        const data = await LettersRoundsService.search(params);

        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.filterSlot.totalRows = data.total;
        this.filterSlot.toPage = data.to;
        this.filterSlot.startRow = data.from;
        this.filterSlot.endRow = data.to;
        return data.data || [];
      } catch (error) {
        console.error("Something went wrong with the provider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async deleteRoundLetter(id) {
      try {
        const result = await this.showConfirmSwal();
        if (!result.isConfirmed) return;
        const params = { id: id, user_id: this.currentUser.user_id };
        const response = await ClientOptions.deleteRoundLetter(params);
        if (response.status === 200) {
          this.showSuccessSwal();
          await this.A_COUNTER_PENDING_ROUND_LETTERS();
          this.$refs.letterRoundTable.refresh();
        }
      } catch (error) {
        console.error("Error trying to delete a round letter", error);
      }
    },

    openModalEditRoundLetter(ncr_id, client_account_id, account, name_client) {
      this.ncrId = ncr_id;
      this.clientAccountId = client_account_id;
      this.accountId = account;
      this.nameClient = name_client;
      this.modalEditRoundLetterState = true;
    },

    openModalTracking(data) {
      this.dataClient = data;
      this.modalTrackingState = true;
    },

    openModalProcess(data) {
      this.dataProcess = data;
      this.modalProcessState = true;
    },

    badgeVariant(value) {
      if (value == "CERTIFIED") {
        return "light-warning";
      } else {
        return "light-success";
      }
    },

    filterColumn(key, bool) {
      let field = this.Fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    openModalAddRound(data) {
      // ********************************
      this.itemClient = {
        id: data.ncr_letters_id,
        idNrl: data.id,
        idAccount: data.client_account_id,
        account: data.client_account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          itin: data.itin,
          other: data.other,
          origin_country: data.origin_country,
        },
        onlyRound: true,
        isDirect: { id: data.id, title: data.round_title, format: data.format },
      };
      this.modalAddRoundLetterState = true;
    },
    openModalFiles(item) {
      this.dataModalFile = {
        ...item,
        ...{ typeTab: this.typeTab, account: item.client_account },
      };
    },
    async joinPdf(item) {
      try {
        const result = await this.showQuestionSwal("Are you sure?");
        if (result.isConfirmed) {
          this.mergeFileModal.item = item;
          this.mergeFileModal.viewMergedPdf = false;
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async viewPdfMerge(route_file_pdf, item = null, direct = false) {
      try {
        if (direct) {
          const { data } = await LettersRoundsService.addUrlTemporaryPdfNcr({
            file_pdf: "/" + route_file_pdf,
          });
          this.urlPdfMerge = data;
        } else {
          this.mergeFileModal.item = item;
          this.mergeFileModal.viewMergedPdf = true;
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async generateCourt(item) {
      try {
        const title =
          item.generated_court === "YES"
            ? "Are you sure to generate it again?"
            : "Are you sures?";
        const text =
          item.generated_court == "YES"
            ? "a court has already been generated in the round.!"
            : "You won't be able to revert this!";
        const result = await this.showQuestionSwal(title, text);
        if (result.isConfirmed) {
          this.addPreloader();
          const params = {
            round_id: item.id,
            user_id: this.currentUser.user_id,
          };
          const data = await LettersRoundsService.generateCourt(params);
          this.showToast("success", "top-right", "Success", "CheckIcon", data);
          this.$refs["letterRoundTable"].refresh();
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        this.removePreloader();
      }
    },
    closeModalMerge(route) {
      this.mergeFileModal.item = null;
      this.mergeFileModal.viewMergedPdf = false;
      if (route) {
        this.$refs.letterRoundTable.refresh();
        this.viewPdfMerge(route, null, true);
      }
    },
  },
  watch: {
    updateLetterTable(newState) {
      if (newState) {
        this.$refs.letterRoundTable.refresh();
        this.A_UPDATE_TABLE(false);
      }
    },
  },
};
</script>
