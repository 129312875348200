<template>
  <div>
    <b-modal
      ref="modal-file"
      centered
      size="xmd"
      header-class="p-0"
      header-bg-variant="transparent border-bottom border-bottom-2"
      title-class="h2 text-white"
      modal-class="modal-primary"
      @hidden="hideModal"
      :hide-footer="selectedFiles.length < 1"
      ok-title="Upload"
      @ok.prevent="saveFilesRound()"
    >
      <template v-slot:modal-header>
        <div class="w-100">
          <b-button-close
            style="transform: translate(-12px, 4px)"
            @click="hideModal()"
          />
          <b-container fluid>
            <b-row class="text-center bg-primary px-3 pt-1 py-1">
              <h3 class="font-weight-bolder text-white">FILES</h3>
            </b-row>
            <b-row class="my-1 px-2">
              <b-col cols="6">
                <h5>CLIENT</h5>
                <div class="rounded border-primary font-medium-1 py10 pl-2">
                  <p class="m-0 p-0 text-primary">{{ dataFile.client_name }}</p>
                </div>
              </b-col>
              <b-col cols="6">
                <h5>ACCOUNT</h5>
                <div class="rounded border-primary font-medium-1 py10 pl-2">
                  <p class="m-0 p-0 text-primary">{{ dataFile.account }}</p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </template>
      <b-row class="my-1 w-100 d-flex justify-content-end font-weight-bold">
        <div v-if="permittedFiles">
          <b-button pill variant="success" @click="activeView(1)">
            <feather-icon icon="UploadIcon" /><span class="iconButtonPadding"
              >UPLOAD NEW FILE</span
            >
          </b-button>
          <b-button pill variant="primary" class="ml-1" @click="activeView(2)">
            <feather-icon icon="FileTextIcon" /><span class="iconButtonPadding"
              >SELECT FROM FILES</span
            >
          </b-button>
        </div>

        <b-button
          v-if="statusNewFile || statusFiles"
          pill
          variant="warning"
          class="ml-1"
          @click="activeView()"
        >
          <feather-icon icon="ChevronLeftIcon" />
          <span class="iconButtonPadding">RETURN</span>
        </b-button>
      </b-row>
      <b-container v-if="statusLettersList">
        <b-table
          slot="table"
          ref="refFilesRound"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :isBusy.sync="isBusy"
          :fields="fields"
          :items="myProvider"
        >
          <!-- Loading -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(file_name)="data">
            <div class="mt-1">
              <a
                target="_blank"
                class="text-primary"
                @click="openPdf(data.item)"
              >
                {{ data.item.file_name }}
              </a>
            </div>
          </template>

          <template #cell(size)="data">
            <div>
              <span> {{ data.item.size }} KB</span>
            </div>
          </template>
          <template #cell(created_by)="data">
            <div>
              <p class="m-0">{{ data.item.created_by }}</p>
              <p class="m-0">{{ data.item.created_at | myGlobalDay }}</p>
            </div>
          </template>
          <template #cell(actions)="data">
            <div
              v-if="
                data.item.created_by != 'System TI' &&
                dataFile.typeTab != 4 &&
                !data.item.type
              "
              class="text-center"
            >
              <b-button
                v-b-tooltip.hover.top="'Delete'"
                variant="flat-danger"
                class="button-little-size rounded-circle"
                @click="deleteFile(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-container>

      <b-container v-if="statusFiles">
        <select-files :dataFile="dataFile" v-model="selectedFiles" />
      </b-container>

      <b-container v-if="statusNewFile">
        <b-row class="mt-1">
          <b-col cols="12">
            <drag-and-drop v-model="newFile" :files-array="newFile" />
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center my-2">
          <b-button class="ml-1" variant="success" @click="setFile()">
            <feather-icon icon="UploadIcon" />
            <span class="iconButtonPadding" style="font-weight: bold"
              >Upload</span
            >
          </b-button>
        </b-row>
      </b-container>
    </b-modal>
    <PdfViewer :url="urlPdf" v-if="urlPdf" @close="urlPdf = null" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalHeader from "@/views/correspondence/views/letters/components/modal/ModalHeader.vue";
import LettersService from "@/views/administration/views/letters/services/letters.service.js";
import CorrespondenceLettersService from "@/views/correspondence/views/letters/services/letters.services";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import SelectFiles from "@/views/administration/views/letters/components/modal/SelectFiles.vue";
import PdfViewer from "@/views/components/pdf-viewer/PdfViewer.vue";

export default {
  components: { ModalHeader, DragAndDrop, SelectFiles, PdfViewer },
  props: {
    dataFile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urlPdf: null,
      fields: [
        {
          key: "file_name",
          label: "Name",
        },
        {
          key: "size",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Upload By",
          key: "created_by",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      statusNewFile: false,
      statusFiles: false,
      statusLettersList: true,
      newFile: [],
      selectedFiles: [],
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    permittedFiles() {
      return (
        !this.statusNewFile &&
        !this.statusFiles &&
        [1, 2].includes(this.dataFile.typeTab) &&
        this.dataFile.request_work_plan_id == null &&
        false
      );
    },
  },
  mounted() {
    this.toggleModal("modal-file");
  },
  methods: {
    hideModal() {
      this.$refs['modal-file'].hide();
      this.$emit("hideModal");
    },
    async saveFilesRound() {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure you want to upload these files?",
          null
        );
        if (!isConfirmed) return;
        this.addPreloader();
        let idFiles = [];
        this.selectedFiles.forEach((element) => {
          idFiles.push(element.id);
        });
        await LettersService.saveFilesRound({
          id: this.dataFile.id,
          files: idFiles,
        });
        this.statusNewFile = false;
        this.statusFiles = false;
        this.statusLettersList = true;
        this.selectedFiles = [];
        this.showSuccessSwal("Files uploaded successfully");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async myProvider() {
      try {
        const { data } = await LettersService.getFilesRound({
          id: this.dataFile.id,
        });

        let defaultData = [
          {
            bureau: 1,

            file_name: "TUR.pdf",
            size: 1,
            created_by: "System TI",
            created_at: "2022-08-24",
            url: `?round=${this.dataFile.id}&bureau=1&idfile=0&namefile=TUR.pdf`,
            newroute: null,
            visible: this.dataFile.sumtr > 0,
          },
          {
            bureau: 2,

            file_name: "EXR.pdf",
            size: 1,
            created_by: "System TI",
            created_at: "2022-08-24",
            url: `?round=${this.dataFile.id}&bureau=2&idfile=0&namefile=EXR.pdf`,
            newroute: null,
            visible: this.dataFile.sumex > 0,
          },
          {
            bureau: 3,
            file_name: "EQR.pdf",
            size: 1,
            created_by: "System TI",
            created_at: "2022-08-24",
            url: `?round=${this.dataFile.id}&bureau=3&idfile=0&namefile=EQR.pdf`,
            newroute: null,
            visible: this.dataFile.sumeq > 0,
          },
        ];

        defaultData = defaultData.filter((item) => item.visible === true);
        const clearData = data.filter(
          (item) =>
            item.file_name != "TUR.pdf" &&
            item.file_name != "EXR.pdf" &&
            item.file_name != "EQR.pdf"
        );
        return [...defaultData, ...clearData];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async openPdf(dataClient) {
      if (dataClient.newroute) {
        const ext = dataClient.file_name.split(".")?.pop();
        if (ext == "pdf") {
          this.urlPdf = dataClient.newroute;
          return;
        }
        window.open(dataClient.newroute, "_blank");
      } else {
        const result =
          await CorrespondenceLettersService.getCorrespondenceLettersPdf({
            round: this.dataFile.id,
            bureau:
              dataClient.file_name == "TUR.pdf"
                ? 1
                : dataClient.file_name == "EXR.pdf"
                ? 2
                : 3,
            idfile: dataClient.id,
            namefile: dataClient.file_name,
          });
        if (result) {
          const url = await this.getUrlBlobPdf(result);
          this.urlPdf = url;
        }
      }
    },
    activeView(type) {
      if (type == 1) {
        this.statusNewFile = true;
        this.statusFiles = false;
        this.statusLettersList = false;
      } else if (type == 2) {
        this.statusNewFile = false;
        this.statusFiles = true;
        this.statusLettersList = false;
      } else {
        this.statusNewFile = false;
        this.statusFiles = false;
        this.statusLettersList = true;
      }
    },
    async setFile() {
      try {
        const formData = new FormData();
        if (this.newFile !== []) {
          this.addPreloader();
          this.newFile.forEach((file) => {
            formData.append("images[]", file, file.name);
          });
          formData.append("round", this.dataFile.id);
          formData.append("user_id", this.currentUser.user_id);
          const { status } = await LettersService.uploadFileDragAndDropRound(
            formData
          );
          if (status == 200) {
            this.statusNewFile = false;
            this.statusFiles = false;
            this.statusLettersList = true;
            this.newFile = [];
            this.removePreloader();
            this.showSuccessSwal("Files uploaded successfully");
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async deleteFile(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const { status } = await LettersService.deleteFileRound({ id: id });
          if (status == 200) {
            this.statusNewFile = false;
            this.statusFiles = false;
            this.statusLettersList = true;
            this.$refs["refFilesRound"].refresh();
            this.removePreloader();
          }
        }
      } catch (error) {}
    },
  },
};
</script>
<style scoped lang="scss">
.quill-editor {
  height: 100px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.iconButtonPadding {
  padding: 16px 0px 0px 4px;
}
</style>
