import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

const filters = [
  {
    key: "from",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    nameFilter: "from",
  },
  {
    key: "to",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    nameFilter: "to",
  },
  {
    key: "sent_from",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Sent From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    nameFilter: "from",
    visible: true,
  },
  {
    key: "sent_to",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Send To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    nameFilter: "to",
    visible: true,
  },
  {
    key: "module",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Module",
    model: null,
    options: [
      { id: 14, text: "Bookeeping" },
      { id: 7, text: "Boost Credit" },
      { id: 3, text: "Business" },
      { id: 10, text: "Court Info" },
      { id: 20, text: "Connection" },
      { id: 6, text: "Credit Experts Regular" },
      { id: 22, text: "Customer Service Digital" },
      { id: 5, text: "Debt Solution" },
      { id: 12, text: "Paragon" },
      { id: 11, text: "Specialist" },
      { id: 8, text: "Tax Research" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 12,
    visible: true,
  },
  {
    key: "type",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type",
    model: null,
    options: [
      { value: 0, label: "ALL" },
      { value: 1, label: "REGULAR" },
      { value: 2, label: "CERTIFIED" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
  },
  {
    key: "created_by",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Created by",
    model: null,
    options: [],
    reduce: "id",
    selectText: "text",
    cols: 12,
    visible: true,
  },
];

const processFilters = async () => {
  const { users } = await RequestWorkPlanService.getCreditAnalysts();
  filters[6].options = users;
};

processFilters();

export default filters;
