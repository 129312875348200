<template>
  <div>
    <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeCompletedInCorrespondence }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >In Correspondece</b-nav-item
      >
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeCompletedSent }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >Sent</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <LetterRoundTable />
    </b-card>
  </div>
</template>
  <script>
import LetterRoundTable from "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/components/LetterRoundTable.vue";

export default {
  components: {
    LetterRoundTable,
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
  },
};
</script>
