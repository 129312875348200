<template>
  <div>
    <b-modal
      v-model="modalOpened"
      size="lg"
      title-class="h3 text-white font-weight-bolder"
      :title="'EDIT ' + text_name"
      header-class="b-vue-modal-header"
      modal-class="top-modal"
      hide-footer
      @hidden="closeModal"
    >
      <div>
        <ValidationObserver ref="form">
          <b-row>
            <b-col :md="direct ? 12 : 6" xs="12">
              <b-row>
                <b-col md="12">
                  <ValidationProvider name="applicant" rules="required" v-slot="{errors}">
                    <b-form-group class="mt-2">
                      <b-input-group>
                        <b-input-group-prepend class="w-35">
                          <b-input-group-text class="w-100 badge-name-group">
                            <span>ACCOUNT</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="account"
                          type="text"
                          disabled
                          :class="{'border border-danger':errors[0]}"
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>
            <b-col :md="direct ? 12 : 6" xs="12">
              <b-row>
                <b-col md="12">
                  <b-form-group class="mt-2">
                    <b-input-group>
                      <b-input-group-prepend class="w-35">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>CLIENT</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input v-model="nameClient" type="text" disabled></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col :md="direct ? 12 : 6">
              <ValidationProvider rules="required" v-slot="{errors}">
                <b-form-group class="mt-1">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>TYPE</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="type_send"
                      :disabled="type_send == 4"
                      :class="{'border border-danger':errors[0]}"
                      style="appearance: none !important"
                    >
                      <b-form-select-option value="4" v-if="direct">PHONE</b-form-select-option>
                      <b-form-select-option value="5" v-if="direct">ONLINE</b-form-select-option>
                      <b-form-select-option value="1" v-if="!direct">REGULAR</b-form-select-option>
                      <b-form-select-option value="2" v-if="!direct">CERTIFIED</b-form-select-option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="6" v-if="type_send == 2">
              <ValidationProvider rules="required" v-slot="{errors}">
                <b-form-group class="mt-1">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>BUREAU</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="bureau_id"
                      :class="{'border border-danger':errors[0]}"
                      required
                      style="appearance: none !important"
                    >
                      <b-form-select-option
                        v-for="item in bureaus"
                        :value="item.id"
                        :key="item.id"
                      >{{ item.name }}</b-form-select-option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" v-if="!direct">
              <ValidationProvider rules="required" v-slot="{errors}">
                <b-form-group class="mt-1">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>TITLE</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="title"
                      :class="{'border border-danger':errors[0]}"
                      required
                      style="appearance: none !important"
                      :disabled="direct"
                    >
                      <b-form-select-option
                        v-for="item in titles"
                        :value="item.id"
                        :key="item.id"

                      >{{ item.value }}</b-form-select-option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="6" v-if="!direct">
              <ValidationProvider rules="required" v-slot="{errors}">
                <b-form-group class="mt-1">
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>FORMAT</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                      v-model="format"
                      :class="{'border border-danger':errors[0]}"
                      required
                      style="appearance: none !important"
                    >
                      <b-form-select-option
                        v-for="item in formats"
                        :value="item.id"
                        :key="item.id"
                      >{{ item.value }}</b-form-select-option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="12">
              <ValidationProvider rules="required" v-slot="{errors}">
                <b-form-group class="mt-2">
                  <b-input-group style="height: auto">
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100 badge-name-group">
                        <span>SECURE NUMBER</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-radio-group
                      v-model="social"
                      class="form-control text-center bg-transparent"
                      :class="{'border border-danger':errors[0]}"
                      style="height: auto"
                    >
                      <b-form-radio
                        :value="ss.ssn"
                        v-if="ss.ssn"
                        @change="changeSocialType(1)"
                      >{{ 'SSN: '+ ss.ssn }}</b-form-radio>
                      <b-form-radio
                        :value="ss.itin"
                        v-if="ss.itin"
                        @change="changeSocialType(2)"
                      >{{ 'ITIN: ' + ss.itin }}</b-form-radio>
                      <b-form-radio
                        :value="ss.other"
                        v-if="ss.other"
                        @change="changeSocialType(3)"
                      >{{ 'CPN: ' + ss.other }}</b-form-radio>
                    </b-radio-group>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-row class="class-inline">
                <b-col
                  md="5"
                  class="class-campo-icon add-class-campo-icon"
                  style="border-radius: 10px 10px 0px 0px"
                >
                  <span>OBSERVATION</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <div class="form-group mt-0">
                <b-form-textarea
                  v-model="obs"
                  class="b-form-style wysiwyg-notes"
                  style="border-radius: 0px 10px 10px 10px"
                ></b-form-textarea>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <div class="offset-4 col-4 text-center">
              <b-button variant="success" class="rounded mt-2 mb-1" @click="editRoundLetter()">
                <i class="fas fa-save"></i>
                UPDATE
              </b-button>
            </div>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import LettersService from "../../services/letters-round.service";
import ClientesOptions from "@/views/commons/components/clients/dashboard/services/clients.options.services";
export default {
  props: {
    idround: String,
    account: String,
    nameClient: String,
    idaccount: String,
    direct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titles: [],
      formats: [],
      bureaus: [],
      text_name: this.direct ? "DIRECT DISPUTE" : "ROUND LETTERS",
      title: "",
      format: "",
      modalOpened: true,
      obs: "",
      type_round: 1,
      type_send: 1,
      bureau_id: 1,
      ss: {
        ssn: null,
        itin: null,
        other: null,
      },
      social: "",
      type_social: "",
    };
  },
  async created() {
    this.addPreloader();
    await Promise.all([
      this.getRoundLetterData(),
      this.getAllSecuresSocial(),
      this.getBureaus(),
      this.getAllFormats(),
      this.getAllTitles(),
    ]);
    await this.removePreloader();
  },
  methods: {
    async getBureaus() {
      try {
        let result = await LettersService.getAllBureaus();
        this.bureaus = result;
      } catch (error) {
        console.error("Error trying to get all bureaus", error);
      }
    },
    async getAllFormats() {
      try {
        let result = await LettersService.getAllFormats();
        this.formats = result;
      } catch (error) {
        console.error("Error trying to get all formats", error);
      }
    },
    async getAllTitles() {
      try {
        let result = await LettersService.getAllTitles();
        this.titles = result;
      } catch (error) {
        console.error("Error trying to get all titles", error);
      }
    },
    async getAllSecuresSocial() {
      try {
        let result = await LettersService.getAllSecuresSocial({ ncrid: this.idround });
        if(result.length > 0) { this.ss = result[0] }
      } catch (error) {
        console.error("Error trying to get all secures social", error);
      }
    },
    async getRoundLetterData() {
      try {
        const data = await LettersService.getRoundLetterData({ id: this.idround });
        this.title       = data[0].title_id;
        this.format      = data[0].format_id;
        this.type_send   = data[0].type_send_id;
        this.type_round  = data[0].type_send_id == 3 ? 1 : 0;
        this.bureau_id   = data[0].bureau_send_id;
        this.obs         = data[0].obs;
        this.social      = data[0].lead_ssn;
        this.type_social = data[0].ss_type;
      } catch (error) {
        console.error("Error tring to get round letter data", error);
      }
    },
    changeSocialType(type) {
      this.type_social = type;
    },
    async editRoundLetter() {
      const result = await this.showConfirmSwal();
      if(!result.isConfirmed) return;
      this.addPreloader();
      try {
        const response = await ClientesOptions.editRound({
          id: this.idround,
          title: this.title,
          format: this.format,
          obs: this.obs,
          user: this.currentUser.user_id,
          social: this.social,
          type_social: this.type_social,
          type_send: this.type_send,
          bureau_id: this.bureau_id
        });
        if(response.status === 200) {
          this.removePreloader();
          this.showSuccessSwal();
          this.$emit("updateTable");
          this.closeModal();
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error("Error trying to edit a round letter", error);
      }
    },
    closeModal() {
      this.modalOpened = false;
      this.$emit("hideModal");
    },
    close() {
      this.modalOpened = false;
      this.$emit("hideModal");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  }
};
</script>
