export default [
  {
    key: "client",
    label: "Client",
    class: "text-left",
    visible: true,
  },
  {
    key: "type",
    label: "type",
    class: "text-center",
    visible: true,
  },
  {
    key: "round",
    label: "Round",
    class: "text-center",
    visible: true,
  },
  {
    key: "created_by",
    label: "Created by",
    class: "text-center",
    visible: false,
  },
  {
    key: "date_send",
    label: "Sent At",
    class: "text-center",
    visible: false,
  },
  {
    key: "deleted_by",
    label: "Deleted by",
    class: "text-center",
    visible: true,
  },
  {
    key: "file",
    label: "Files",
    class: "text-center",
    visible: true,
  },
  {
    key: "generated_merge",
    label: "Merge",
    class: "text-center",
    visible: true,
  },
  {
    key: "generated_court",
    label: "Court",
    class: "text-center",
    visible: false,
  },
  {
    key: "procesed_by",
    label: "PROCESED By",
    class: "text-center",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-center",
    visible: true,
  },
];
