var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"lg","title-class":"h3 text-white font-weight-bolder","title":'EDIT ' + _vm.text_name,"header-class":"b-vue-modal-header","modal-class":"top-modal","hide-footer":""},on:{"hidden":_vm.closeModal},model:{value:(_vm.modalOpened),callback:function ($$v) {_vm.modalOpened=$$v},expression:"modalOpened"}},[_c('div',[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":_vm.direct ? 12 : 6,"xs":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"applicant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-2"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-35"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("ACCOUNT")])])],1),_c('b-form-input',{class:{'border border-danger':errors[0]},attrs:{"type":"text","disabled":""},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}})],1)],1)]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":_vm.direct ? 12 : 6,"xs":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mt-2"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-35"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("CLIENT")])])],1),_c('b-form-input',{attrs:{"type":"text","disabled":""},model:{value:(_vm.nameClient),callback:function ($$v) {_vm.nameClient=$$v},expression:"nameClient"}})],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":_vm.direct ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-35"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("TYPE")])])],1),_c('b-form-select',{class:{'border border-danger':errors[0]},staticStyle:{"appearance":"none !important"},attrs:{"disabled":_vm.type_send == 4},model:{value:(_vm.type_send),callback:function ($$v) {_vm.type_send=$$v},expression:"type_send"}},[(_vm.direct)?_c('b-form-select-option',{attrs:{"value":"4"}},[_vm._v("PHONE")]):_vm._e(),(_vm.direct)?_c('b-form-select-option',{attrs:{"value":"5"}},[_vm._v("ONLINE")]):_vm._e(),(!_vm.direct)?_c('b-form-select-option',{attrs:{"value":"1"}},[_vm._v("REGULAR")]):_vm._e(),(!_vm.direct)?_c('b-form-select-option',{attrs:{"value":"2"}},[_vm._v("CERTIFIED")]):_vm._e()],1)],1)],1)]}}])})],1),(_vm.type_send == 2)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-35"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("BUREAU")])])],1),_c('b-form-select',{class:{'border border-danger':errors[0]},staticStyle:{"appearance":"none !important"},attrs:{"required":""},model:{value:(_vm.bureau_id),callback:function ($$v) {_vm.bureau_id=$$v},expression:"bureau_id"}},_vm._l((_vm.bureaus),function(item){return _c('b-form-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)]}}],null,false,1691273275)})],1):_vm._e()],1),_c('b-row',[(!_vm.direct)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-35"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("TITLE")])])],1),_c('b-form-select',{class:{'border border-danger':errors[0]},staticStyle:{"appearance":"none !important"},attrs:{"required":"","disabled":_vm.direct},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}},_vm._l((_vm.titles),function(item){return _c('b-form-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.value))])}),1)],1)],1)]}}],null,false,1563344442)})],1):_vm._e(),(!_vm.direct)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-35"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("FORMAT")])])],1),_c('b-form-select',{class:{'border border-danger':errors[0]},staticStyle:{"appearance":"none !important"},attrs:{"required":""},model:{value:(_vm.format),callback:function ($$v) {_vm.format=$$v},expression:"format"}},_vm._l((_vm.formats),function(item){return _c('b-form-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.value))])}),1)],1)],1)]}}],null,false,516235474)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-2"},[_c('b-input-group',{staticStyle:{"height":"auto"}},[_c('b-input-group-prepend',{staticClass:"w-35"},[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("SECURE NUMBER")])])],1),_c('b-radio-group',{staticClass:"form-control text-center bg-transparent",class:{'border border-danger':errors[0]},staticStyle:{"height":"auto"},model:{value:(_vm.social),callback:function ($$v) {_vm.social=$$v},expression:"social"}},[(_vm.ss.ssn)?_c('b-form-radio',{attrs:{"value":_vm.ss.ssn},on:{"change":function($event){return _vm.changeSocialType(1)}}},[_vm._v(_vm._s('SSN: '+ _vm.ss.ssn))]):_vm._e(),(_vm.ss.itin)?_c('b-form-radio',{attrs:{"value":_vm.ss.itin},on:{"change":function($event){return _vm.changeSocialType(2)}}},[_vm._v(_vm._s('ITIN: ' + _vm.ss.itin))]):_vm._e(),(_vm.ss.other)?_c('b-form-radio',{attrs:{"value":_vm.ss.other},on:{"change":function($event){return _vm.changeSocialType(3)}}},[_vm._v(_vm._s('CPN: ' + _vm.ss.other))]):_vm._e()],1)],1)],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{staticClass:"class-inline"},[_c('b-col',{staticClass:"class-campo-icon add-class-campo-icon",staticStyle:{"border-radius":"10px 10px 0px 0px"},attrs:{"md":"5"}},[_c('span',[_vm._v("OBSERVATION")])])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-group mt-0"},[_c('b-form-textarea',{staticClass:"b-form-style wysiwyg-notes",staticStyle:{"border-radius":"0px 10px 10px 10px"},model:{value:(_vm.obs),callback:function ($$v) {_vm.obs=$$v},expression:"obs"}})],1)])],1),_c('b-row',[_c('div',{staticClass:"offset-4 col-4 text-center"},[_c('b-button',{staticClass:"rounded mt-2 mb-1",attrs:{"variant":"success"},on:{"click":function($event){return _vm.editRoundLetter()}}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" UPDATE ")])],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }