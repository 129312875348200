<template>
  <div>
    <b-modal
      ref="modalName"
      title-class="h2 text-white"
      modal-class="modal-primary"
      title="MERGE FILES"
      size="lg"
      @hidden="closeModal()"
      centered
      no-close-on-backdrop
      header-class="p-0"
      :hide-footer="statusNewFile || viewMergedPdf"
      header-bg-variant="transparent border-bottom border-bottom-2"
    >
      <template v-slot:modal-header>
        <div class="w-100">
          <b-button-close
            style="transform: translate(-12px, 4px)"
            @click="closeModal()"
          />
          <b-container fluid>
            <b-row class="text-center bg-primary px-3 pt-1 py-1">
              <h3 class="font-weight-bolder text-white">MERGE FILES</h3>
            </b-row>
            <b-row class="my-1 px-2">
              <b-col cols="6">
                <h5>CLIENT</h5>
                <div class="rounded border-primary font-medium-1 py10 pl-2">
                  <p class="m-0 p-0 text-primary">{{ item.client_name }}</p>
                </div>
              </b-col>
              <b-col cols="6">
                <h5>ACCOUNT</h5>
                <div class="rounded border-primary font-medium-1 py10 pl-2">
                  <p class="m-0 p-0 text-primary">{{ item.client_account }}</p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </template>
      <template v-if="!viewMergedPdf">
        <b-row class="my-1 w-100 d-flex justify-content-end font-weight-bold">
          <b-button
            v-if="!statusNewFile"
            pill
            variant="success"
            @click="activeView"
          >
            <feather-icon icon="UploadIcon" /><span class="iconButtonPadding"
              >UPLOAD NEW FILE</span
            >
          </b-button>
          <b-button
            v-if="statusNewFile"
            pill
            variant="warning"
            class="ml-1"
            @click="activeView"
          >
            <feather-icon icon="ChevronLeftIcon" />
            <span class="iconButtonPadding">RETURN</span>
          </b-button>
        </b-row>
        <b-container v-if="!statusNewFile">
          <b-table-simple responsive sticky-header="50vh" no-border-collapse>
            <b-thead>
              <b-tr>
                <th style="width: 10px !important"></th>
                <b-th>Name</b-th>
                <b-th>Size</b-th>
                <b-th>Upload By</b-th>
              </b-tr>
            </b-thead>
            <tbody>
              <b-tr v-for="(item, index) in myProvider" :key="index">
                <b-td>
                  <b-form-checkbox
                    v-model="item.check"
                    :disabled="item.disabled"
                    @change="onChecked(index)"
                  />
                </b-td>
                <b-td>
                  <div class="mt-1">
                    <a
                      target="_blank"
                      class="text-primary"
                      @click="openPdf(item)"
                    >
                      {{ item.file_name }}
                    </a>
                  </div>
                </b-td>
                <b-td>{{ getSizeFile(item.size) }}</b-td>
                <b-td>
                  <div>
                    <p class="m-0">{{ item.created_by }}</p>
                    <p class="m-0">{{ item.created_at | myGlobalDay }}</p>
                  </div>
                </b-td>
              </b-tr>
            </tbody>
          </b-table-simple>
        </b-container>
        <b-container v-if="statusNewFile">
          <b-row class="mt-1">
            <b-col cols="12">
              <drag-and-drop v-model="newFile" :files-array="newFile" />
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center my-2">
            <b-button class="ml-1" variant="success" @click="setFile()">
              <feather-icon icon="UploadIcon" />
              <span class="iconButtonPadding" style="font-weight: bold"
                >Upload</span
              >
            </b-button>
          </b-row>
        </b-container>
      </template>
      <template v-else>
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>Name</b-th>
              <b-th>Size</b-th>
              <b-th>Upload By</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in dataMergedPdfFiles" :key="index">
              <b-td>
                <div class="mt-1">
                  <a
                    target="_blank"
                    class="text-primary"
                    @click="openPdf(item)"
                  >
                    {{ item.file_name }}
                  </a>
                </div>
              </b-td>
              <b-td>{{ item.size }}</b-td>
              <b-td>
                <div>
                  <p class="m-0">{{ item.created_by }}</p>
                  <p class="m-0">{{ item.created_at | myGlobalDay }}</p>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
      <template #modal-footer>
        <b-button variant="primary" @click.prevent="mergeFiles">Merge</b-button>
      </template>
    </b-modal>
    <PdfViewer :url="urlPdf" v-if="urlPdf" @close="urlPdf = null" />
  </div>
</template>
<script>
import LettersService from "@/views/administration/views/letters/services/letters.service";
import LettersRoundsService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import PdfViewer from "@/views/components/pdf-viewer/PdfViewer.vue";
import CorrespondenceLettersService from "@/views/correspondence/views/letters/services/letters.services";
import { mapGetters } from "vuex";
export default {
  name: "ModalMergeFiles",
  props: {
    item: {
      required: true,
      type: Object,
    },
    viewMergedPdf: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    DragAndDrop,
    PdfViewer,
  },
  data() {
    return {
      urlPdf: "",
      myProvider: [],
      fields: [
        {
          key: "file_name",
          label: "Name",
        },
        {
          key: "size",
          thClass: "text-center",
          tdClass: "text-center",
          label: "Size",
        },
        {
          label: "Upload By",
          key: "created_by",
          thClass: "text-center",
        },
      ],
      isBusy: false,
      selectFiles: [],
      allSelected: false,
      indeterminate: false,
      statusNewFile: false,
      newFile: [],
      nameFileMerge: "",
      bureauFile: {
        1: "TUR",
        2: "EXP",
        3: "EQR",
      },
      bureau: "",
      dataMergedPdfFiles: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  watch: {
    myProvider: {
      handler(data) {
        const bureauDate = data.filter((item) => !item.disabled && item.bureau);
        if (bureauDate.length === 2) {
          const bureau = bureauDate[0].bureau;
          this.nameFileMerge = this.bureauFile[bureau];
          this.bureau = bureau;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.addPreloader();
    await this.getFiles();
    this.removePreloader();
    this.toggleModal("modalName");
  },
  created() {},
  methods: {
    activeView() {
      this.statusNewFile = !this.statusNewFile;
      this.selectFiles = this.myProvider;
    },
    closeModal() {
      this.$refs['modalName'].hide();
      this.$emit("close");
    },
    async openPdf(dataClient) {
      if (dataClient.newroute) {
        const ext = dataClient.file_name.split(".")?.pop();
        if (ext == "pdf") {
          this.urlPdf = dataClient.newroute;
          return;
        }
        window.open(dataClient.newroute, "_blank");
      } else {
        const result =
          await CorrespondenceLettersService.getCorrespondenceLettersPdf({
            round: this.item.id,
            bureau:
              dataClient.file_name == "TUR.pdf"
                ? 1
                : dataClient.file_name == "EXR.pdf"
                ? 2
                : 3,
            idfile: dataClient.id,
            namefile: dataClient.file_name,
          });
        if (result) {
          const url = await this.getUrlBlobPdf(result);
          this.urlPdf = url;
        }
      }
    },
    async setFile() {
      try {
        const formData = new FormData();
        if (this.newFile !== []) {
          this.addPreloader();
          this.newFile.forEach((file) => {
            formData.append("images[]", file, file.name);
          });
          formData.append("round", this.item.id);
          formData.append("user_id", this.currentUser.user_id);
          const { status } = await LettersService.uploadFileDragAndDropRound(
            formData
          );
          if (status == 200) {
            this.statusNewFile = false;
            this.newFile = [];
            await this.getFiles();
            this.removePreloader();
            this.showSuccessSwal("Files uploaded successfully");
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getFiles() {
      try {
        if (this.viewMergedPdf) {
          await this.getMergedPdfFiles();
        } else {
          await this.getFileForMerge();
        }
      } catch (ex) {}
    },
    async getFileForMerge() {
      const { data } = await LettersService.getFilesRound({
        id: this.item.id,
      });

      let defaultData = [
        {
          id: "bureau_1",
          bureau: 1,
          file_name: "TUR.pdf",
          size: 1,
          created_by: "System TI",
          created_at: "2022-08-24",
          url: `?round=${this.item.id}&bureau=1&idfile=0&namefile=TUR.pdf`,
          newroute: null,
          visible: this.item.sumtr > 0,
          sumtr: this.item.sumtr ? this.item.sumtr : 0,
        },
        {
          id: "bureau_2",
          bureau: 2,
          file_name: "EXR.pdf",
          size: 1,
          created_by: "System TI",
          created_at: "2022-08-24",
          url: `?round=${this.item.id}&bureau=2&idfile=0&namefile=EXR.pdf`,
          newroute: null,
          visible: this.item.sumex > 0,
          sumex: this.item.sumex ? this.item.sumex : 0,
        },
        {
          id: "bureau_3",
          bureau: 3,
          file_name: "EQR.pdf",
          size: 1,
          created_by: "System TI",
          created_at: "2022-08-24",
          url: `?round=${this.item.id}&bureau=3&idfile=0&namefile=EQR.pdf`,
          newroute: null,
          visible: this.item.sumeq > 0,
          sumeq: this.item.sumeq ? this.item.sumeq : 0,
        },
      ];

      defaultData = defaultData.filter((item) => item.visible === true);
      const clearData = data.filter(
        (item) =>
          item.file_name != "TUR.pdf" &&
          item.file_name != "EXR.pdf" &&
          item.file_name != "EQR.pdf"
      );
      this.myProvider = [...defaultData, ...clearData];

      if (this.selectFiles.length > 0) {
        this.myProvider.forEach((item) => {
          const exists = this.selectFiles.find((x) => x.id === item.id);
          if (exists) {
            item.check = exists.check;
            item.disabled = exists.disabled;
          }
        });
      } else {
        this.myProvider = this.myProvider.map((x) => ({
          ...x,
          check: false,
          disabled: false,
        }));
      }
    },
    async getMergedPdfFiles() {
      const data = await LettersRoundsService.getMergedPdfFiles({
        round_id: this.item.id,
      });
      this.dataMergedPdfFiles = data;
    },
    onChecked(index) {
      const elementSelected = this.myProvider[index];
      this.myProvider.map((item, i) => {
        if (item.bureau && elementSelected.bureau) {
          if (elementSelected.check) {
            if (elementSelected.bureau === item.bureau) {
              item.disabled = false;
            } else {
              item.disabled = true;
            }
          } else {
            const exists = this.myProvider.filter((x) => x.check && x.bureau);
            if (exists.length === 0) {
              item.disabled = false;
            }
          }
        }
      });
    },
    checkAllFiles() {
      this.myProvider = this.myProvider.map((item) => ({
        ...item,
        check: this.allSelected,
      }));
    },
    async mergeFiles() {
      try {
        const onCheck = this.myProvider.filter((x) => x.check);

        if (onCheck.length < 2) throw new Error("select at least 2 files");

        const result = await this.showQuestionSwal("Are you sures?");
        if (result.isConfirmed) {
          const selectedFiles = onCheck.map((item) => ({
            site: item.newroute ? "s3" : "local",
            route: item.newroute ? this.getUrlS3(item) : item.file_name,
            sumeq: item.sumeq ?? 0,
            sumex: item.sumex ?? 0,
            sumtr: item.sumtr ?? 0,
            bureau: item.bureau ?? null,
          }));

          const params = {
            round_id: this.item.id,
            filesArray: selectedFiles,
            nameFileMerge: this.nameFileMerge,
            bureau: this.bureau,
            user_id: this.currentUser.user_id,
          };

          this.addPreloader();
          const data = await LettersRoundsService.joinPdf(params);
          this.removePreloader();
          this.$emit("close", data);
        }
      } catch (ex) {
        this.showToast("danger", "top-right", "Error", "XIcon", ex.message);
      }
    },
    getUrlS3(item) {
      return `${item.route}/${item.s3_name}`;
    },
    getSizeFile(bytes, precision = 2) {
      const units = ["B", "KB", "MB", "GB", "TB"];
      bytes = parseInt(bytes.toString().replace(",", ""), 10); // Elimina la coma y convierte a entero
      const pow = Math.floor((bytes ? Math.log(bytes) : 0) / Math.log(1024));
      const powIndex = Math.min(pow, units.length - 1);
      bytes /= Math.pow(1024, pow);
      return `${bytes.toFixed(precision)} ${units[powIndex]}`;
    },
  },
};
</script>
